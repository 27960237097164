<template>
    <div>
        <!--zh-cn-->
        <div v-if="lang == 'zh_CN'">
            <section class="wrapper">
                <h1 id="tos">什么是 Keystore？</h1>
                <p>Keystore 文件是钱包存储私钥的一种文件格式 (JSON)。它使用用户自定义密码加密，以起到一定程度上的保护作用，而保护的程度取决于用户加密该钱包的密码强度，如果类似于 123456 这样的密码，是极为不安全的。</p>
                <p><strong>在使用 Keystore 时有两点需要注意</strong></p>
                <ol>
                    <li><span class="wysiwyg-color-black">使用不常用，并且尽可能复杂的密码加密 Keystore 文件；</span></li>
                    <li><span class="wysiwyg-color-black">一定要记住加密 Keystore 的密码，一旦忘记密码，那么你就失去了 Keystore 的使用权，并且 Infinity Wallet 无法帮你找回密码，所以一定要妥善保管好 Keystore 以及密码。</span></li>
                </ol>
                <p><strong>Keystore 的样式&nbsp;</strong></p>
                <p>&nbsp;<img src="@/assets/keystore.png" alt="keystore.png">&nbsp;</p>
                <p>Keystore 的密码是唯一、不可更改的，如果想更改钱包密码需要使用助记词或明文私钥重新导入钱包，并使用新密码加密，生成新的 Keystore。</p>
            </section>
        </div>

        <!--en-->
        <div v-else-if="lang == 'en'">
            <section class="wrapper">
                <h1 id="tos">What is a keystore? </h1>
                <p>Keystore file is a file format (JSON) for the wallet to store private keys. It uses user-defined password encryption to provide a certain degree of protection, and the degree of protection depends on the strength of the user's password to encrypt the wallet. A password similar to 123456 is extremely insecure. </p>
                <p><strong>There are two points to note when using Keystore</strong></p>
                <ol>
                    <li><span class="wysiwyg-color-black">Encrypt the Keystore file with a password that is not commonly used and is as complex as possible;</span></li>
                    <li><span class="wysiwyg-color-black">Be sure to remember the password of the encrypted Keystore. Once you forget the password, you will lose the right to use the Keystore, and Infinity Wallet cannot help you retrieve the password, so be sure Keep the Keystore and password properly. </span></li>
                </ol>
                <p><strong>Keystore style&nbsp;</strong></p>
                <p>&nbsp;<img src="@/assets/keystore.png" alt="keystore.png">&nbsp;</p>
                <p>The password of the Keystore is unique and cannot be changed. If you want to change the wallet password, you need to re-import the wallet with a mnemonic or plain text private key, and encrypt it with the new password to generate a new Keystore. </p>
            </section>
        </div>

        <!--zh_TW-->
        <div v-else>
            <section class="wrapper">
                <h1 id="tos">什麼是 Keystore？ </h1>
                <p>Keystore 文件是錢包存儲私鑰的一種文件格式 (JSON)。它使用用戶自定義密碼加密，以起到一定程度上的保護作用，而保護的程度取決於用戶加密該錢包的密碼強度，如果類似於 123456 這樣的密碼，是極為不安全的。 </p>
                <p><strong>在使用 Keystore 時有兩點需要注意</strong></p>
                <ol>
                    <li><span class="wysiwyg-color-black">使用不常用，並且盡可能複雜的密碼加密 Keystore 文件；</span></li>
                    <li><span class="wysiwyg-color-black">一定要記住加密Keystore 的密碼，一旦忘記密碼，那麼你就失去了Keystore 的使用權，並且Infinity Wallet 無法幫你找回密碼，所以一定要妥善保管好Keystore 以及密碼。 </span></li>
                </ol>
                <p><strong>Keystore 的樣式&nbsp;</strong></p>
                <p>&nbsp;<img src="@/assets/keystore.png" alt="keystore.png">&nbsp;</p>
                <p>Keystore 的密碼是唯一、不可更改的，如果想更改錢包密碼需要使用助記詞或明文私鑰重新導入錢包，並使用新密碼加密，生成新的 Keystore。 </p>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Keystore',
        components: {},
        props: {},
        data() {
            return {
                lang: this.$route.params.lang || 'en'
            }
        },
        computed: {},
        methods: {},
        mounted() {

        },
    }
</script>

<style>

</style>
